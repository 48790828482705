.modal-page {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.457);
  backdrop-filter: blur(3px);
  z-index: 1;

}


.modal-alert-page-container {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);


  /*  */

  background: rgb(0, 0, 0);
  width: 389px;
  height: 241px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.modal-alert-box {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}




.modal-alert-title {
  margin-top: 10px;
  margin-bottom: 15px;
  text-align: center;
  text-transform: uppercase;
  line-height: 27px;
  font-size: 24px;
  font-weight: 700;
  color: rgba(0, 176, 225, 1);

}


