/* about */

.form-about-title {
  font-size: 16px;
  font-weight: 600;
  color: #FFFFFF;

}


.form-about-subtitle {
  font-size: 14px;
  font-weight: 300;
  color: #FFFFFF;

}