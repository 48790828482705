.header-title {
  text-align: center;
  width: 100%;
  height: max-content;
  font-size: 16px;
  line-height: 22px;
  font-weight: 300;
  color: #FFFFFF;

  /*  */

  margin-bottom: 5px;
}

.header-subtitle {

  width: 100%;
  height: max-content;
  font-size: 16px;
  line-height: 22px;
  font-weight: 300;
  color: #FFFFFF;

  /*  */

  margin-bottom: 5px;
}


.header-subtitle-bot {

  width: 100%;
  height: max-content;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  color: #FFFFFF;

  /*  */

  margin-bottom: 5px;

}



.u-img {
  margin-top: 6px;
  overflow: hidden;


  animation: letterUp 2s ease;
}

.t-img {
  margin-left: 10px;
  overflow: hidden;


  animation: letterUp 2s ease;
}

.v-img {
  overflow: hidden;

  animation: letterUp 2s ease;
}

.prod-img {
  animation: textOpacity 2s ease-in-out;
}


/* animation */


@keyframes letterUp {
  0% {
    transform: translateY(-200px);
  }
  100% {
    transform: translateY(0px)
  }

}


@keyframes textOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }

}


