.select-title {
  width: 100%;
  height: max-content;
  font-size: 14px;
  font-weight: 600;
  color: #FFFFFF;

  /*  */

  margin-bottom: 5px;
}

.select-subtitle {

  width: 100%;
  height: max-content;
  font-size: 10px;
  font-weight: 200;
  color: #ffffff;

  /*  */

  margin-bottom: 5px;

}



input::placeholder {

  font-size: 14px;

 }